import React from "react";
import CheckIcon from "../../components/Icon/Check/index"
import WarningIcon from "../../components/Icon/Warning/index"
import MinusIcon from "../../components/Icon/Minus/index"

class GeneDetail extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
    }

    render() {
        return (
            // <table className="table table-borderless">
            // <tbody>
            <div>
                {this.data.gene_list.map((item) => (
                    <div>
                        <hr />
                        <div className="row mt-1 mb-1">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <h5 style={{ color: "black" }}>{item.gene}</h5>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                {item.evidence}
                            </div>
                        </div>
                        <div className="row mt-1 mb-1">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                {item.position}
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                {item.result}
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2 mb-3">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {item.influence}
                                {(() => {
                                    if (item.recommend_id === 1) {
                                        return (
                                            <CheckIcon desc={item.recommend}></CheckIcon>
                                        )
                                    } else if (item.recommend_id === 4) {
                                        return (
                                            <MinusIcon desc={item.recommend}></MinusIcon>
                                        )
                                    } else {
                                        return (
                                            <WarningIcon desc={item.recommend}></WarningIcon>
                                        )
                                    }
                                })()}
                            </div>
                        </div>

                        {(() => {
                              if (item.interpretation) {
                                return (
                                    <div className="pb-2">
                                        <div className="row mt-1 mb-1">
                                            <div style={{ color: "#5a5c69" }} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <h6>Interpretation:</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                {item.interpretation}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        })()}
                        {(() => {
                              if (item.guideline_list.length > 0) {
                                const listItems = item.guideline_list.map((guideline_l,index) =>
                                    <p>{index+1}. {guideline_l}</p>
                                );
                                return (
                                    <div>
                                        <div className="row mt-1 mb-1">
                                            <div style={{ color: "#5a5c69" }} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <h6>Guideline:</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                {listItems}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        })()}
                        {(() => {
                              if (item.reference_list.length > 0) {
                                const listItems = item.reference_list.map((val, index) =>  
                                    <p>{index+1}. {val.source} {val.url}</p>
                                );
                                return (
                                    <div>
                                        <div className="row mb-1">
                                            <div style={{ color: "#5a5c69" }} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <h6>Reference:</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                {listItems}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        })()}
                    </div>
                ))}
            </div>
            // </tbody>
            // </table>
        );
    }
}

export default GeneDetail;
