import React, { Component } from 'react';

import { reactLocalStorage } from 'reactjs-localstorage';
import kinyoodeeIcon from '../../../assets/img/kinyoodeeIcon.png'
import { Link } from 'react-router-dom';

class Topbar extends Component {
    constructor(props) {
        super(props);
    }
    logout() {
        reactLocalStorage.clear();
    }

    render() {
        const profileName = reactLocalStorage.get("profile_name");

        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav">

                    {/* <!-- Nav Item - Alerts --> */}
                    <div>
                        <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="img-profile rounded-circle" src={kinyoodeeIcon} />
                                <span className="mr-2 ml-2 text-gray-600 larg">KinYoodee</span>
                            </a>
                        </li>
                    </div>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 text-gray-600 small">{profileName}</span>
                        </a>
                        {/* <!-- Dropdown - User Information --> */}

                        <Link to='/' >
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown" onClick={this.logout}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout
                            </div>
                        </Link>
                    </li>

                </ul>

            </nav>

        )
    }
}

export default Topbar;