import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";

class BasicTable extends React.Component {

    constructor(props) {
        super(props)
        this.columns = []
        this.datas = []
        this.keyField = ""
    }

   render() {
    // const { SearchBar } = Search;
    const MySearch = (props) => {
      let input;
      const handleClick = () => {
        props.onSearch(input.value);
      };
      return (
        <div>
          <input
            className="form-control"
            // style={ { backgroundColor: 'pink' } }
            ref={ n => input = n }
            type="text"
            placeholder="Search Here"
            onInput={ handleClick }
          />
        </div>
      );
    };
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      totalSize: this.props.datas.length
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        {/* <PaginationListStandalone { ...paginationProps } /> */}
        <ToolkitProvider
          keyField={this.props.keyField}
          columns={ this.props.columns }
          data={ this.props.datas }
          search
        >
          {
            toolkitprops => (
              <div>
                {/* <SearchBar { ...toolkitprops.searchProps } /> */}
                <MySearch { ...toolkitprops.searchProps } />
                <br/>
                <BootstrapTable
                  striped
                  hover
                  { ...toolkitprops.baseProps }
                  { ...paginationTableProps }
                />
              </div>
            )
          }
        </ToolkitProvider>
        <PaginationListStandalone { ...paginationProps } />
      </div>
    );

    return (
      <div>
        <PaginationProvider
          pagination={
            paginationFactory(options)
          }
        >
          { contentTable }
        </PaginationProvider>
      </div >
    );
  }
}

export default BasicTable;