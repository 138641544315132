import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Topbar from "../../components/Navigation/Topbar/index";
import ChartDonut from "../../components/Charts/Donut/index";
import { reactLocalStorage } from "reactjs-localstorage";
import Swal from "sweetalert2";
import CardBasic from "../../components/Cards/Basic";
import SelectSearch from "react-select-search";

import { getDrugReportDemo } from "../../api/helper";

import BasicTable from "../../components/Table/BasicTable";
import Star from "../../components/Rating/Star";
import CheckIcon from "../../components/Icon/Check/index";
import WarningIcon from "../../components/Icon/Warning/index";
import InfoIcon from "../../components/Icon/Info/index";
import MinusIcon from "../../components/Icon/Minus/index";
import Footer from "../Footer/index";

function Demo() {
  const [refreshData, setRefreshData] = useState([]);
  const [subtitlesText, setSubtitlesText] = useState("");
  const [dataPoints, setDataPoints] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [drug, setDrug] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionsDrugClassName, setOptionsDrugClassName] = useState([]);

  //table
  const columns = [
    {
      dataField: "name_en",
      text: "Name",
    },
    {
      dataField: "name_en_search",
      text: "",
      hidden: true,
    },
    {
      dataField: "overall_recommend",
      text: "Overall Recommend",
    },
    {
      dataField: "overall_recommend_search",
      text: "",
      hidden: true,
    },
    {
      dataField: "evidence",
      text: "Evidence",
    },
  ];

  const pieOptions = {
    title: "Drug Risk",
    pieHole: 0.4,
  };

  useEffect(() => {
    getDrugReport();
  }, [refreshData]);

  async function getDrugReport() {
    const param = {
      token: "73d26ca7b4513f8913a539a8b57f0798",
      user_profile: 3 
    };
    const response = await getDrugReportDemo(param);
    if (response.data.error === 0) {
      const data = await response.data.data;
      reactLocalStorage.set("profile_name", `${data.profile_name}`);
      setSubtitlesText(data.drug_risk + "/" + data.drug_total);
      setDrug(data);
      setDataPoints([
        ["Drug Risk", "Risk"],
        ["No Risk", data.drug_total],
        ["Risk", data.drug_risk],
      ]);
      getDrugList(data.drug_list);
      setRefreshData(true);
      setLoading(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          typeof response.data.errmsg !== "undefined"
            ? response.data.errmsg
            : "GET path: dna/pharmcard/get/noauth fail.",
      });
    }
  }

  function getDrugList(drugList) {
    let arrDrugList = [];
    let arrDrugClassName = [];
    let option = {
      name: "All Drug Class",
      value: "All",
    };
    arrDrugClassName.push(option);

    drugList.forEach((x, key) => {
      let data = {
        key: key,
        name_en: (
          <Link
            to={{
              pathname: "/demo-details",
              state: { data: x },
            }}
          >
            {x.drug_name_en}
          </Link>
        ),
        name_en_search: x.drug_name_en,
        overall_recommend:
          x.overall_recommend_id === 1 ? (
            <CheckIcon desc={x.overall_recommend}></CheckIcon>
          ) : x.overall_recommend_id === 4 ? (
            <MinusIcon desc={x.overall_recommend}></MinusIcon>
          ) : (
            <WarningIcon desc={x.overall_recommend}></WarningIcon>
          ),
        overall_recommend_search: x.overall_recommend,
        evidence: <Star count={5} value={x.evidence}></Star>,
      };

      arrDrugList.push(data);
      let option = {
        name: x.drug_class_name,
        value: x.drug_class_name,
      };
      arrDrugClassName.push(option);
    });
    setDrugList(arrDrugList);
    let uniqueDrugClasName = [
      ...new Map(
        arrDrugClassName.map((item) => [item["value"], item])
      ).values(),
    ];
    setOptionsDrugClassName(uniqueDrugClasName);
  }

  function filterDrugClassName(value) {
    let arrDrugList = [];
    let filterDrugList = drug.drug_list;
    if (value !== "All") {
      filterDrugList = drug.drug_list.filter(
        (x) => x.drug_class_name === value
      );
    }
    let drugRisk = filterDrugList.filter(
      (x) => x.overall_recommend_id != 1
    ).length;
    setSubtitlesText(drugRisk + "/" + drug.drug_total);
    setDataPoints([
      ["Drug Risk", "Risk"],
      ["No Risk", drug.drug_total],
      ["Risk", drugRisk],
    ]);

    filterDrugList.forEach((x, key) => {
      let data = {
        key: key,
        name_en: (
          <Link
            to={{
              pathname: "/demo/details",
              state: { data: x },
            }}
          >
            {x.drug_name_en}
          </Link>
        ),
        name_en_search: x.drug_name_en,
        overall_recommend:
          x.overall_recommend_id === 1 ? (
            <CheckIcon desc={x.overall_recommend}></CheckIcon>
          ) : x.overall_recommend_id === 4 ? (
            <MinusIcon desc={x.overall_recommend}></MinusIcon>
          ) : (
            <WarningIcon desc={x.overall_recommend}></WarningIcon>
          ),
        overall_recommend_search: x.overall_recommend,
        evidence: <Star count={5} value={x.evidence}></Star>,
      };
      arrDrugList.push(data);
    });
    setDrugList(arrDrugList);
  }

  // render() {
  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">

              {/* <!-- Page Heading --> */}
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <CardBasic>
                    <div className="row ">
                      <div className="col-0 col-sm-0 col-md-4 col-lg-4 col-xl-4"></div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                        <h3 style={{ color: "#4E4B4B" }}>Drug</h3>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <SelectSearch
                          options={optionsDrugClassName}
                          search
                          name="drugClassName"
                          placeholder="Drug Class Name"
                          defaultValue="All"
                          onChange={(value) => filterDrugClassName(value)}
                        />
                      </div>
                    </div>
                  </CardBasic>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <ChartDonut
                    pieData={dataPoints}
                    pieOptions={pieOptions}
                    riskDesc={subtitlesText}
                    loading={loading}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <CardBasic title="Infomation">
                    <table className="table table-borderless mb-4">
                      <tbody>
                        <tr>
                          <td className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {drug.method}
                          </td>
                          <td className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {drug.created_by_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {drug.report_date}
                          </td>
                          <td className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <Link
                              to={{
                                pathname: "/demo-info",
                                state: { data: drug },
                              }}
                            >
                              <InfoIcon desc="PGx Labs" />
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <BasicTable
                      keyField="key"
                      datas={drugList}
                      columns={columns}
                    ></BasicTable>
                  </CardBasic>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <Footer />
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
//}
}

export default Demo;
